<template>
  <div>
    <img
      src="https://static.5t1800.com/s3/2023/05/09/6459dc839e910.jpg"
      alt=""
    />
    <div class="productsImg">
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dc976fecd.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dc9d2729d.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dca3819de.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dca8cba8c.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dcb01009b.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dcb82609b.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dcc05c494.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dcc5d765b.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dccb9e8a3.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dcd244c03.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/05/09/6459dcd950a68.png"
        alt=""
      />
      <img
        src="https://static.5t1800.com/s3/2023/08/29/64ed62a182f60.png"
        alt=""
      />
    </div>
    <!-- 更多产品 -->
    <div class="productsMore flex a_c j_c hidden-xs-only">
      <!-- <div>更多薇妮护肤产品，请至官方小程序查看</div> -->
      <img
        src="https://static.5t1800.com/s3/2023/08/29/64ed5bad2308b.png"
        alt=""
      />
    </div>

     <!-- 更多产品 H5 -->
    <div class="productsMorePhone hidden-sm-and-up">
      <!-- <div>更多薇妮护肤产品，请至官方小程序查看</div> -->
      <img
        src="https://static.5t1800.com/s3/2023/08/29/64ed5bad2308b.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  metaInfo: {
    title: "Vinistyle 薇妮", // set a title
    meta: [
      {
        name: "keyWords",
        content: "薇妮vinistyle,薇妮,薇妮美肤,薇尼美肤空间,护肤品,化妆品加盟,薇妮化妆品加盟,化妆品,薇妮化妆品,美白,防晒,防晒霜",
      },
      {
        name: "description",
        content: "vinistyle薇妮是江苏苏美国际旗下化妆品品牌，主营功效性护肤产品和解决方案，包括美容院专业线套组以及居家护肤产品，针对用户常见的斑、痘、敏、皱四大类肌肤问题，都有针对性的产品和解决方案。vinistyle薇妮提供整店输出、产品合作、项目合作等各种合作方式，诚邀美容院、皮肤管理工作、化妆品实体店加盟合作，共创美丽事业！",
      },
    ],
  },
  created() {
    this.$store.commit("saveTypeIndex", 3);
  },
  components: {},
  methods: {},
};
</script>

<style scoped>
img {
  width: 100%;
  display: block;
}
.productsImg {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 8px;
}
.productsImg img {
  margin-bottom: 10px;
}
.productsMore {
  max-width: 1200px;
  margin:0 auto;
  font-size: 24px;
  font-weight: 400;
  color: #838383;
  padding:30px 0;
}
.productsMore img {
  width:100%;
  height:100%;
  /* margin-left:100px; */
}




.productsMorePhone {
  font-size: 18px;
  font-weight: 400;
  color: #838383;
  padding:30px 0;
  text-align: center;
}
.productsMorePhone img {
 width:60%;
 display: block;
 margin:0 auto;
 margin-top:20px;
}
</style>

